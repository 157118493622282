import { type Reducer } from "redux"

import { type BookingsState, BookingsTypes } from "./types"

const INITIAL_STATE: BookingsState = {
  data: { },
  error: false,
  loading: false,
  errorMessage: ""
}

const reducer: Reducer<BookingsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BookingsTypes.LOAD_REQUEST:
      return { ...state, loading: true }
    case BookingsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload }
    case BookingsTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, errorMessage: action.payload, data: {} }
    case BookingsTypes.RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

export default reducer
