import { type Reducer } from "redux"

import { type PixState, PixTypes } from "./types"

const INITIAL_STATE: PixState = {
  data: [],
  error: false,
  loading: false,
  loadingData: false,
  selectedPix: undefined,
  loadingPixList: false
}

const reducer: Reducer<PixState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PixTypes.GENERATE_PIX:
      return { ...state, loading: true }
    case PixTypes.GENERATE_PIX_SUCCESS:
      return { ...state, loading: false, error: false, selectedPix: action.payload }
    case PixTypes.GENERATE_PIX_FAILURE:
      return { ...state, loading: false, error: true }
    case PixTypes.SELECT_PIX:
      return { ...state, selectedPix: action.payload }
    case PixTypes.GET_PIX_DATA_SUCCESS:
      return { ...state, loadingData: false }
    case PixTypes.GET_PIX_DATA:
      return { ...state, loadingData: true }
    case PixTypes.GET_PIX_LIST:
      return { ...state, loadingPixList: true }
    case PixTypes.GET_PIX_LIST_SUCCESS:
      return { ...state, data: action.payload, loadingPixList: false }
    case PixTypes.GET_PIX_LIST_FAILURE:
      return { ...state, data: [], loadingPixList: false }
    default:
      return state
  }
}

export default reducer
