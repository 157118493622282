import { type Dayjs } from "dayjs"

/**
 * Action types
 */
export enum LoginTypes {
  "SIGNIN" = "@login/SIGNIN",
  "SIGNIN_SUCCESS" = "@login/SIGNIN_SUCCESS",
  "SIGNIN_FAILURE" = "@login/SIGNIN_FAILURE",
  "REFRESH" = "@login/REFRESH",
  "REFRESH_SUCCESS" = "@login/REFRESH_SUCCESS",
  "REFRESH_FAILURE" = "@login/REFRESH_FAILURE"
}

/**
 * Data types
 */
export interface Login {
  accessToken?: string
  refreshToken?: string
  tokenType?: string
  expiresIn?: string
  expiresAt?: Date
}

/**
 * State type
 */
export interface LoginState {
  readonly data: Login
  readonly loading: boolean
  readonly error: boolean
  readonly errorMessage: string

  readonly lastAttempt?: Dayjs
  readonly attempts: number
  readonly blocked: boolean
  readonly blockedUntil?: Dayjs

  readonly refreshError: boolean
}
