import axios from "axios"

import { type Invoice } from "../store/ducks/invoices/types"
import delay from "../utils/delay"

export interface LoginData {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: string
}

export interface BookingData {
  "id": string
  "idNum": number
  "communityId": number
  "status": {
    "key": string
    "label": string
  }
  "type": {
    "key": string
    "label": string
  }
  "createdAt": string
  "updatedAt": string
  "createdBy": string
  "pickUpDatetime": string
  "dropOffDatetime": string
  "duration": string
  "isOneWay": boolean
  "durationChargeType": {
    "key": string
    "label": string
  }
  "estimatedCost": {
    "amount": number
    "currencyName": string
    "currencySymbol": string
    "distanceThreshold": string
    "distanceDescription": string
    "description": string
    "paymentSchedule"?: Array<{
      "dueDateTime": string
      "estimatedCost": {
        "amount": number
        "currencyName": string
        "currencySymbol": string
        "distanceThreshold": string
        "distanceDescription": string
        "description": string
      }
    }>
  }
  "invoiceTotal": number
  "journey": {
    "pickUpDatetime": string
    "dropOffDatetime": string
    "distance": number
    "distanceUnit": null
    "duration": string
    "fuelLevel": null
    "fuelLevelUnit": null
  }
  "hasSpecialRequests": boolean
  "isDifferentCommunityVehicle": boolean
  purpose: string
}

export interface InvoicesData {
  invoices: Invoice[]

}

const { REACT_APP_API_GTS_ENDPOINT } = process.env

async function login (username: string, password: string): Promise<LoginData> {
  if (process.env.REACT_APP_MOCK_GTS) {
    await delay(3000)

    return ({
      access_token: "EXEMPLO",
      expires_in: "3600",
      refresh_token: "EXEMPLO",
      token_type: "bearer"
    })
  }

  const response = await axios.post(`${REACT_APP_API_GTS_ENDPOINT}/login`, {
    username,
    password
  })

  return response.data
}

async function refresh (refreshToken: string): Promise<LoginData> {
  if (process.env.REACT_APP_MOCK_GTS) {
    await delay(3000)

    return ({
      access_token: "EXEMPLO",
      expires_in: "3600",
      refresh_token: "EXEMPLO",
      token_type: "bearer"
    })
  }

  const response = await axios.post(`${REACT_APP_API_GTS_ENDPOINT}/login/refresh`, {
    refreshToken
  })

  return response.data
}

async function bookingData (bookingId: string): Promise<BookingData> {
  if (process.env.REACT_APP_MOCK_GTS) {
    await delay(1000)

    return ({
      id: "l23qjdd",
      idNum: 413217,
      communityId: 103,
      status: {
        key: "finished",
        label: "Finished"
      },
      type: {
        key: "personal",
        label: "Personal"
      },
      createdAt: "2022-10-19T10:36:32-0300",
      updatedAt: "2022-10-19T17:33:17-0300",
      createdBy: "Nilton Cezar Ismael",
      pickUpDatetime: "2022-10-19T12:35:00-0300",
      dropOffDatetime: "2022-10-19T14:35:00-0300",
      duration: "2 hours",
      isOneWay: false,
      durationChargeType: {
        key: "actual_end_time",
        label: "Actual End Time"
      },
      estimatedCost: {
        amount: 44,
        currencyName: "BRL",
        currencySymbol: "R$",
        distanceThreshold: "",
        distanceDescription: "",
        description: "44,00 R$"
      },
      invoiceTotal: 110,
      journey: {
        pickUpDatetime: "2022-10-19T12:35:00-0300",
        dropOffDatetime: "2022-10-19T17:33:17-0300",
        distance: 0,
        distanceUnit: null,
        duration: "4 hours 58 minutes",
        fuelLevel: null,
        fuelLevelUnit: null
      },
      hasSpecialRequests: false,
      isDifferentCommunityVehicle: false,
      purpose: "Unai"
    })
  }

  const response = await axios.get(`${REACT_APP_API_GTS_ENDPOINT}/booking/${bookingId.trim()}`, {
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response.data
}

async function invoicesFromBooking (bookingId: string): Promise<InvoicesData> {
  if (process.env.REACT_APP_MOCK_GTS) {
    await delay(1000)

    return ({
      invoices: [
        {
          id: 526589,
          status: {
            key: "pending",
            label: "Pending"
          },
          errorMessage: null,
          currency: "BRL",
          currencySymbol: "R$",
          parent: null,
          balancingInvoice: null,
          type: "Invoice",
          purpose: {
            key: "booking_balance",
            label: "Booking Balance"
          },
          chargeType: {
            key: "manual",
            label: "Manual"
          },
          taxMode: "INCLUSIVE",
          subtotal: 110,
          total: 110,
          refunded: 0,
          refundable: 110,
          lineItems: [
            {
              id: 1496689,
              fee: "Taxa de duração",
              description: "4 horas e 58 minutos entre 19/10/22, 12:35 e 19/10/22, 17:33 em 22,00 R$/hora",
              amount: 110
            }
          ],
          taxes: [],
          surcharges: [],
          transactions: [],
          booking: {
            id: "l23qjdd",
            idNum: 413217,
            community: {},
            communityId: 103,
            status: {
              key: "finished",
              label: "Finished"
            },
            type: {
              key: "personal",
              label: "Personal"
            },
            createdAt: "2022-10-19T10:36:32-0300",
            updatedAt: "2022-10-19T17:33:17-0300",
            createdBy: "Nilton Cezar Ismael",
            pickUpDatetime: "2022-10-19T12:35:00-0300",
            dropOffDatetime: "2022-10-19T14:35:00-0300",
            duration: "2 hours",
            vehicle: {},
            user: {},
            stationPickUp: {},
            stationDropOff: {},
            isOneWay: false,
            durationChargeType: {
              key: "actual_end_time",
              label: "Actual End Time"
            },
            estimatedCost: {
              amount: 44,
              currencyName: "BRL",
              currencySymbol: "R$",
              distanceThreshold: "",
              distanceDescription: "",
              description: "44,00 R$"
            },
            invoiceTotal: 110,
            journey: {
              pickUpDatetime: "2022-10-19T12:35:00-0300",
              dropOffDatetime: "2022-10-19T17:33:17-0300",
              distance: 0,
              distanceUnit: null,
              duration: "4 hours 58 minutes",
              fuelLevel: null,
              fuelLevelUnit: null
            },
            hasSpecialRequests: false,
            isDifferentCommunityVehicle: true
          },
          createdAt: "2022-10-19T20:33:18+0000",
          createdBy: "system",
          updatedAt: "2022-10-19T20:33:18+0000"
        }
      ]
    })
  }

  const response = await axios.get(`${REACT_APP_API_GTS_ENDPOINT}/booking/${bookingId.trim()}/invoices`, {
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response.data
}

export default {
  login,
  refresh,
  bookingData,
  invoicesFromBooking
}
