import axios from "axios"

import { type Liveness } from "../store/ducks/liveness/types"

export interface LivenessListData {
  livenessList: Liveness[]
}

const { REACT_APP_API_LIVENESS_ENDPOINT } = process.env

async function newLiveness (gtsBookingId: string): Promise<unknown> {
  const response = await axios.post(`${REACT_APP_API_LIVENESS_ENDPOINT}/create/${gtsBookingId}`, {}, {
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response.data
}

async function livenessList (payload: { gtsBookingId?: string, gtsUserId?: string }): Promise<LivenessListData> {
  const response = await axios.get(`${REACT_APP_API_LIVENESS_ENDPOINT}`, {
    params: {
      bookingId: payload.gtsBookingId,
      userId: payload.gtsUserId
    },
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response.data
}
export default {
  newLiveness,
  livenessList
}
