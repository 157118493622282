import Icon from "@ant-design/icons"
import { type CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"
import React from "react"

const PIXSvg = (): React.SVGProps<SVGSVGElement> => (<svg width="1em" height="1em" viewBox="0 0 184 184" fill="currentColor">
<path fill="currentColor" d="
M152.824066,131.565247
C148.022827,131.891464 143.324585,132.795364 139.095047,131.768616
C134.540283,130.662918 129.709381,128.548340 126.258736,125.464355
C117.706116,117.820564 109.566658,109.650055 101.853348,101.153595
C96.453209,95.205185 88.503021,96.841095 84.113892,101.328827
C76.197769,109.422806 68.030830,117.272652 60.164295,125.413528
C55.654068,130.081055 50.480408,131.738358 43.975368,132.244461
C34.835270,132.955566 28.082388,130.375061 22.493614,123.151718
C18.561911,118.070084 13.820023,113.611320 9.397366,108.914482
C3.309032,102.448692 2.755524,82.962433 10.871304,75.521896
C17.614521,69.339722 23.795313,62.547523 30.412142,56.221024
C31.675406,55.013191 33.776539,54.219471 35.549812,54.099022
C39.858124,53.806389 44.372021,53.289085 48.479816,54.247654
C52.276672,55.133659 56.241959,57.184326 59.104546,59.833458
C67.652344,67.743874 75.675705,76.219498 83.942009,84.436035
C90.130203,90.586967 96.819237,90.630730 102.952484,84.542709
C111.224380,76.331825 119.628685,68.242317 127.624176,59.768597
C134.700409,52.269131 143.611221,54.256241 152.190216,54.135014
C152.464340,54.131145 152.805283,54.385960 153.010422,54.614223
C161.160217,63.682636 171.765823,70.559334 177.402542,81.869240
C182.263962,91.623558 180.289902,104.105927 172.583801,111.901260
C166.147675,118.411919 159.620346,124.832436 152.824066,131.565247
z" />
<path fill="currentColor" d="
M130.569031,153.569122
C124.551598,159.586502 118.456810,165.066483 113.086830,171.182816
C102.710190,183.001740 83.792015,183.317490 74.218597,173.784164
C62.934669,162.547501 52.065979,150.893860 40.986172,139.388733
C41.840191,139.320068 43.217999,139.112137 44.595406,139.114716
C52.116798,139.128784 58.445957,136.412842 63.734848,131.074554
C71.584045,123.152031 79.492790,115.288437 87.386536,107.410141
C91.990311,102.815376 94.827095,102.803192 99.554810,107.487572
C106.767540,114.634186 114.179573,121.603844 121.027016,129.087784
C127.280106,135.922119 134.670349,139.402512 145.323456,139.082977
C139.941345,144.365601 135.380234,148.842407 130.569031,153.569122
z" />
<path fill="currentColor" d="
M102.234299,76.266304
C100.801025,77.694740 99.643639,78.893852 98.433342,80.036957
C95.399521,82.902344 91.462471,82.902435 88.441017,79.911713
C81.337029,72.879997 74.122139,65.948860 67.268784,58.678524
C60.003544,50.971249 51.567005,46.341702 39.936852,47.053989
C41.298229,45.483776 42.150211,44.373554 43.130001,43.390739
C52.893028,33.597610 62.910320,24.045382 72.393188,13.988890
C83.003609,2.736636 98.367424,2.941833 110.117386,11.675620
C111.043884,12.364285 111.790924,13.295551 112.615623,14.119821
C123.303604,24.802343 133.990540,35.485912 145.534241,47.025402
C133.578217,46.528248 125.634003,51.931377 118.682762,59.699467
C113.580025,65.401817 107.905685,70.592674 102.234299,76.266304
z" />
</svg>
)

// @ts-expect-error IGNORE
const PIXIcon = (props: Partial<CustomIconComponentProps>): React.ReactElement => (<Icon component={PIXSvg} {...props} />)

export default PIXIcon
