import "./BigHeader.css"

import { Col, Row } from "antd"
import React from "react"

import LogoKintoBrasil from "../../assets/LogoKintoBrasil.svg"

const Header = (): React.ReactElement => (
  <Row className="big-header-bg">
    <Col span={24}>
      <Row>
        <Col>
          <img className="big-header-logo-brasil" src={LogoKintoBrasil} alt={"Logo kinto Brasil"}></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="light-text big-header-subtext">Soluções</span>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default Header
