import "./Workspace.css"

import { BookOutlined, MenuOutlined } from "@ant-design/icons"
import { Col, Layout, Menu, Row } from "antd"
import React, { useState } from "react"
// import { connect } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"

// import { bindActionCreators, type Dispatch } from "redux"
import LogoKinto from "../../assets/LogoKintoSimples.svg"
// import { type ApplicationState } from "../../store"
// import * as SigninActions from "../../store/ducks/signin/actions"

const {
  Header: LayoutHeader,
  Sider,
  Content
} = Layout

// function mapStateToProps (state: ApplicationState): StateProps {
//   return ({
//     refreshError: state.authed.refreshError
//   })
// }

// function mapDispatchToProps (dispatch: Dispatch) {
//   return bindActionCreators(SigninActions, dispatch)
// }

// let refreshTimeoutInstance: NodeJS.Timeout

// interface StateProps {
//   refreshError: boolean
// }

// interface DispatchProps {
//   refresh: (payload: {
//     refreshToken: string
//   }) => void
// }

// type Props = DispatchProps & StateProps

const Workspace = (): React.ReactElement => {
  // const { refresh } = props as Props

  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()

  // const handleLogout = (): void => {
  //   localStorage.setItem("@kinto-solutions/auth/accessToken", "")
  //   localStorage.setItem("@kinto-solutions/auth/refreshToken", "")
  //   localStorage.setItem("@kinto-solutions/auth/expiresAt", "")
  //   localStorage.setItem("@kinto-solutions/auth/source", "")

  //   navigate("/", {
  //     replace: true
  //   })
  // }

  // useEffect(() => {
  //   const refreshToken = localStorage.getItem("@kinto-solutions/auth/refreshToken")
  //   const tokenExpiration = localStorage.getItem("@kinto-solutions/auth/expiresAt")

  //   const expiresInMili = time(tokenExpiration).asDayjs().diff(time(), "ms")
  //   const refreshInMili = 300000 // 300000 ms = 5 minutes

  //   if (expiresInMili <= 0) handleLogout()

  //   if (!refreshTimeoutInstance) {
  //     refreshTimeoutInstance = setTimeout(() => {
  //       refresh({ refreshToken: refreshToken as string })
  //     }, Math.max(expiresInMili - refreshInMili, 0))
  //   }
  // }, [])

  // useEffect(() => {
  //   if (props.refreshError) handleLogout()
  // }, [props.refreshError])

  return (
    <Layout>
      <LayoutHeader style={{ padding: 0, height: "fit-content" }}>
        <Row className="workspace-header-bg" align="middle">
          <Col xs={2} sm={2} md={2} lg={1} xl={1} onClick={() => { setCollapsed(!collapsed) }} style={{ cursor: "pointer" }}>
            <MenuOutlined className="header-icon"/>
          </Col>
          <Col xs={20} lg={22} style={{ justifyContent: "left" }}>
            <img className="header-logo-kinto" src={LogoKinto} alt={"Logo kinto"}></img>
          </Col>
          <Col xs={2} lg={1}>
            {/* <LogoutOutlined className="header-icon" onClick={handleLogout} /> */}
          </Col>
        </Row>
      </LayoutHeader>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={"30px"}
          breakpoint="md"
          onBreakpoint={(broken) => { setCollapsed(broken) }}
        >
          <Menu
          style={{ display: "flex", justifyContent: "center", paddingTop: "5px" }}
          mode="inline"
          items={
            [
              {
                key: 1,
                icon: <BookOutlined/>,
                label: "Booking",
                onClick: () => { navigate("/auth/booking") }
              }
              // {
              //   key: 2,
              //   icon: <PIXIcon style={{ fontSize: "14px" }}/>,
              //   label: "PIX",
              //   disabled: true,
              //   onClick: () => { navigate("/auth/pix") }
              // }
            ]
          }/>

        </Sider>
        <Content className="workspace-content">
          <div className="view">
            <Outlet/>
          </div>
        </Content>
      </Layout>
      {/* <Footer className="workspace-footer"></Footer> */}
    </Layout>
  )
}

export default Workspace
