import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import GTS, { type InvoicesData } from "../../../apis/GTS"
import { getInvoicesFailure, getInvoicesSuccess } from "./actions"
import { type InvoicesTypes } from "./types"

export function * getInvoices (action: PayloadAction<InvoicesTypes, { bookingId: string, token: string }>): Generator {
  try {
    const response = yield call(GTS.invoicesFromBooking, action.payload.bookingId)

    yield put(getInvoicesSuccess((response as InvoicesData).invoices))
  } catch (err) {
    console.warn(err)
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if (err instanceof Error) errorMessage = err.message
    yield put(getInvoicesFailure({ errorMessage }))
  }
}
