export const texts = {
  searchBooking: {
    bookingIdNotFound:
      "Booking não encontrado, por favor verifique o código correto no GTS.",
    notAMatch:
      "Informações do cliente não correspondem com o Booking informado. Por favor confira as informações do cliente no GTS.",
    expiredToken:
      "Seu token expirou, é preciso fazer a busca pelo Booking ID novamente",
  },
  liveness: {
    newProcess: "Novo processo iniciado com sucesso!",
    newLivenessError: [
      "Erro ao Gerar o QR Code, por favor atualize a página e tente inserir os dados do cliente novamente.",
      "Caso o erro persista, por favor tire o print desta tela, e envie para whatsapp (11) 4118-9959 com o número do booking para constar nas nossas métricas.",
      "Descrever com detalhes o processo realizado anteriormente, como qual o navegador e modelo de telefone caso tenha sido feito no telefone.",
      "Siga com o processo padrão junto ao cliente!"
    ],
    qrCodeTile: "Informe o QRCode para o cliente!"
  },
  invoicesList: {
    moreInfo: "Clique no item para mais informações."
  },
  general: {
    expired: "Seu token expirou, você será redirecionada(o) para a busca por bookings novamente.",
    genericError:
      "Obtivemos um erro ao realizar a busca, tente novamente em alguns instantes.",

  }
};
