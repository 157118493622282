import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import PIX, { type GeneratedPix, type GeneratePixProps } from "../../../apis/PIX"
import { generatePixFailure, generatePixSuccess, getPixDataSuccess, getPixListFailure, getPixListSuccess, selectPix } from "./actions"
import { type Pix, type PixTypes } from "./types"

export interface PixDataResponse {
  data?: { pixInfo: Pix }
  error?: string
  success?: string
}

export function * generatePix (action: PayloadAction<PixTypes, GeneratePixProps>): Generator {
  try {
    const response = yield call(PIX.generatePix, action.payload)
    yield put(generatePixSuccess((response as GeneratedPix).pixInfo))
  } catch (err) {
    console.warn(err)
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if (err instanceof Error) errorMessage = err.message
    yield put(generatePixFailure({ errorMessage }))
  }
}

export function * getPixData (action: PayloadAction<PixTypes, number>): Generator {
  try {
    const response = yield call(PIX.getPixData, action.payload, true)

    yield put(getPixDataSuccess())
    yield put(selectPix((response as PixDataResponse).data?.pixInfo))
  } catch (err) {
    console.warn(err)
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if (err instanceof Error) errorMessage = err.message
    yield put(generatePixFailure({ errorMessage }))
  }
}

export function * getPixList (action: PayloadAction<PixTypes, { invoiceId: string, bookingId: string }>): Generator {
  try {
    const { bookingId, invoiceId } = action.payload
    const response = yield call(PIX.pixList, bookingId, invoiceId)

    yield put(getPixListSuccess(response as Pix[]))
  } catch (err) {
    yield put(getPixListFailure())
  }
}
