import { LoadingOutlined } from "@ant-design/icons"
import { Tabs, type TabsProps } from "antd"
import React, { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators, type Dispatch } from "redux"

import { type ApplicationState } from "../../store"
import * as BookingsActions from "../../store/ducks/booking/actions"
import { type Booking } from "../../store/ducks/booking/types"
import BookingInvoices from "../BookingInvoices"
import LivenessView from "../Liveness"

interface StateProps {
  bookings: Booking
  loading: boolean
  error: boolean
  errorMessage?: string
}

interface DispatchProps {
  searchBooking: (payload: {
    bookingId: string
  }) => void
}

type Props = StateProps & DispatchProps

function mapStateToProps (state: ApplicationState): StateProps {
  return ({
    bookings: state.bookings.data,
    loading: state.bookings.loading,
    error: state.bookings.error,
    errorMessage: state.bookings.errorMessage
  })
}

function mapDispatchToProps (dispatch: Dispatch) {
  return bindActionCreators({ ...BookingsActions }, dispatch)
}

const items: TabsProps["items"] = [
  {
    key: "liveness",
    label: "Reconhecimento Facial",
    children: <LivenessView />
  },
  {
    key: "invoices",
    label: "Invoices",
    children: <BookingInvoices />
  }
]

const BookingView = (props: Partial<Props>): React.JSX.Element => {
  const { bookingId } = useParams()

  useMemo(() => {
    if (bookingId && props.searchBooking) props.searchBooking({ bookingId })
  }, [])

  useEffect(() => {
    return () => {
      localStorage.setItem("@kinto-solutions/gts/id", "")
      localStorage.setItem("@kinto-solutions/gts/email", "")
      localStorage.setItem("@kinto-solutions/gts/gtsAccessToken", "")
    }
  }, [])

  return (
    <div className="booking-workspace">
      {
        props.loading
          ? <LoadingOutlined style={{ fontSize: "50px" }}/>
          : <Tabs defaultActiveKey="liveness" items={items} destroyInactiveTabPane={true}/>
      }
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingView)
