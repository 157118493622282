import { action } from "typesafe-actions"

import { type Invoice, InvoicesTypes } from "./types"

export const getInvoices = (data: { bookingId: string }): { type: InvoicesTypes.GET_INVOICES } => action(InvoicesTypes.GET_INVOICES, data)

export const getInvoicesSuccess = (data: Invoice[]): { type: InvoicesTypes.GET_INVOICES_SUCCESS, payload: Invoice[] } => action(InvoicesTypes.GET_INVOICES_SUCCESS, data)

export const getInvoicesFailure = (data: { errorMessage: string }): { type: InvoicesTypes.GET_INVOICES_FAILURE } => action(InvoicesTypes.GET_INVOICES_FAILURE, data)

export const selectInvoice = (data?: Invoice): { type: InvoicesTypes.SELECT_INVOICE, payload?: Invoice } => action(InvoicesTypes.SELECT_INVOICE, data)

export default {
  getInvoices,
  getInvoicesSuccess,
  getInvoicesFailure,
  selectInvoice
}
