import { action } from "typesafe-actions"

import { type Booking, BookingsTypes } from "./types"

export const searchBooking = (data: { bookingId: string }): { type: BookingsTypes.LOAD_REQUEST } => action(BookingsTypes.LOAD_REQUEST, data)

export const searchBookingSuccess = (data: Booking): { type: BookingsTypes.LOAD_SUCCESS, payload: Booking } => action(BookingsTypes.LOAD_SUCCESS, data)

export const searchBookingFailure = (message: string): { type: BookingsTypes.LOAD_FAILURE, payload: string } => action(BookingsTypes.LOAD_FAILURE, message)

export const resetBooking = (): { type: BookingsTypes.RESET } => action(BookingsTypes.RESET)
