import "./index.css"
import "./fonts/ToyotaType-Bold.ttf"
import "./fonts/ToyotaType-Regular.ttf"
import "./fonts/ToyotaType-Semibold.ttf"

import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import { timeAsDayjs } from "./utils/time"

Date.prototype.asDayjs = function (UTC = true) {
  return timeAsDayjs(this, {
    UTC
  })
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
    <App/>
)
