/**
 * Action types
 */
export enum InvoicesTypes {
  "GET_INVOICES" = "@invoices/GET_INVOICES",
  "GET_INVOICES_SUCCESS" = "@invoices/GET_INVOICES_SUCCESS",
  "GET_INVOICES_FAILURE" = "@invoices/GET_INVOICES_FAILURE",
  "SELECT_INVOICE" = "@invoices/SELECT_INVOICE"
}

/**
 * Data types
 */
export interface Invoice {
  "id": number
  "status": {
    "key": string
    "label": string
  }
  "errorMessage"?: string | null
  "currency": string
  "currencySymbol": string
  "parent"?: string | null
  "balancingInvoice"?: string | null
  "type": string
  "purpose": {
    "key": string
    "label": string
  }
  "chargeType": {
    "key": string
    "label": string
  }
  "taxMode": string
  "subtotal": number
  "total": number
  "refunded": number
  "refundable": number
  "lineItems": Array<{
    "id": number
    "fee": string
    "description": string
    "amount": number
  }>
  "taxes": []
  "surcharges": []
  "transactions": []
  "booking": {
    "id": string
    "idNum": number
    "community": unknown
    "communityId": number
    "status": {
      "key": string
      "label": string
    }
    "type": {
      "key": string
      "label": string
    }
    "createdAt": string
    "updatedAt": string
    "createdBy": string
    "pickUpDatetime": string
    "dropOffDatetime": string
    "duration": string
    "vehicle": unknown
    "user": unknown
    "stationPickUp": unknown
    "stationDropOff": unknown
    "isOneWay": boolean
    "durationChargeType": {
      "key": string
      "label": string
    }
    "estimatedCost": {
      "amount": number
      "currencyName": string
      "currencySymbol": string
      "distanceThreshold": string
      "distanceDescription": string
      "description": string
    }
    "invoiceTotal": number
    "journey": {
      "pickUpDatetime": string
      "dropOffDatetime": string
      "distance": number
      "distanceUnit": null
      "duration": string
      "fuelLevel": null
      "fuelLevelUnit": null
    }
    "hasSpecialRequests": boolean
    "isDifferentCommunityVehicle": boolean
  }
  "createdAt": string
  "createdBy": string
  "updatedAt": string
}

/**
 * State type
 */
export interface InvoicesState {
  readonly data: Invoice[]
  readonly selectedInvoice?: Invoice
  readonly loading: boolean
  readonly error: boolean
}
