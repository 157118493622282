import { type AxiosError } from "axios"
import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import GTS, { type LoginData } from "../../../apis/GTS"
import time from "../../../utils/time"
import { refreshFailure, refreshSuccess, signInFailure, signInSuccess } from "./actions"
import { type LoginTypes } from "./types"

function formatLoginDataToProps (data: LoginData) {
  const {
    access_token: accessToken,
    expires_in: expiresIn,
    refresh_token: refreshToken,
    token_type: tokenType
  } = data

  const expiresAt = time().asDayjs(false).add(Number(expiresIn), "seconds")

  return {
    accessToken,
    refreshToken,
    tokenType,
    expiresIn,
    expiresAt
  }
}

export function * authenticate (action: PayloadAction<LoginTypes, { username?: string, password?: string, onSignInSuccess: () => void }>): Generator {
  try {
    const { username, password } = action.payload
    if (!username || !password) throw new Error("Preencha os campos corretamente!")
    const response = yield call(GTS.login, username, password)

    const {
      accessToken,
      expiresAt,
      expiresIn,
      refreshToken,
      tokenType
    } = formatLoginDataToProps(response as LoginData)

    localStorage.setItem("@kinto-solutions/auth/accessToken", accessToken)
    localStorage.setItem("@kinto-solutions/auth/refreshToken", refreshToken)
    localStorage.setItem("@kinto-solutions/auth/expiresAt", expiresAt.format("YYYY-MM-DDTHH:mm:ss"))
    localStorage.setItem("@kinto-solutions/auth/source", "gts")

    yield put(signInSuccess({
      accessToken,
      expiresAt: expiresAt.toDate(),
      expiresIn,
      refreshToken,
      tokenType
    }))

    action.payload.onSignInSuccess()
  } catch (err) {
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    console.log({ err })
    if ((err as AxiosError).isAxiosError) {
      errorMessage = (err as AxiosError<{ message: string }>).response?.data?.message ?? ""
    } else if (err instanceof Error) {
      errorMessage = err.message
    }
    yield put(signInFailure({ errorMessage }))
  }
}

export function * refreshAuthentication (action: PayloadAction<LoginTypes, { refreshToken: string }>): Generator {
  try {
    const { refreshToken: currentRefreshToken } = action.payload

    const response = yield call(GTS.refresh, currentRefreshToken)

    const {
      accessToken,
      expiresAt,
      expiresIn,
      refreshToken,
      tokenType
    } = formatLoginDataToProps(response as LoginData)

    localStorage.setItem("@kinto-solutions/auth/accessToken", accessToken)
    localStorage.setItem("@kinto-solutions/auth/refreshToken", refreshToken)
    localStorage.setItem("@kinto-solutions/auth/expiresAt", expiresAt.format("YYYY-MM-DDTHH:mm:ss"))
    localStorage.setItem("@kinto-solutions/auth/source", "gts")

    yield put(refreshSuccess({
      accessToken,
      expiresAt: expiresAt.toDate(),
      expiresIn,
      refreshToken,
      tokenType
    }))
  } catch (err) {
    yield put(refreshFailure())
  }
}
