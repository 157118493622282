import axios from "axios"

import { type Pix } from "../store/ducks/pix/types"
import delay from "../utils/delay"
import time from "../utils/time"

export interface GeneratePixProps {
  value: number
  info: string
  comments?: Array<{ nome: string, valor: string }>
  bookingId: string
  invoiceId: string
}

export interface GeneratedPix {
  pixInfo: Pix
}

const { REACT_APP_API_PIX_ENDPOINT } = process.env

async function generatePix ({
  bookingId,
  comments,
  info,
  invoiceId,
  value
}: GeneratePixProps): Promise<GeneratedPix> {
  const response = await axios.post(`${REACT_APP_API_PIX_ENDPOINT}`, {
    value,
    info,
    comments,
    bookingId,
    invoiceId
  }, {
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response?.data?.data
}

async function getPixData (id: number, updateStatus?: boolean): Promise<Pix> {
  if (process.env.REACT_APP_MOCK_PIX) {
    await delay(3000)

    console.log(id)
    const success = Math.random()
    let finalStatus: string
    if (success < 0.3) finalStatus = "REMOVIDA_PELO_PSP"
    else if (success < 0.6) finalStatus = "REMOVIDA_PELO_USUARIO_RECEBEDOR"
    else if (success < 0.9) finalStatus = "CONCLUIDA"
    else finalStatus = "ATIVA"

    return ({
      id: 1,
      status: finalStatus,
      txid: "7978c0c97ea847e78e8849634473c1f1",
      valor: 400,
      bookingId: "l23qjdd",
      invoiceId: "526589",
      generatedAt: time().asDayjs().toISOString(),
      expiresAt: time().asDayjs().add(1, "d").toISOString(),
      copiaecola: "00020101021226910014BR.GOV.BCB.PIX2569spi-h.itau.com.br/pix/qr/v2/cob/af581bdc-624e-4333-af38-1adaddfa6ce05204000053039865802BR5914PMD BASHAR RIO6009SAO PAULO62070503***6304E7DB"
    })
  }

  const pixData = await axios.get(`${REACT_APP_API_PIX_ENDPOINT}/${id}`, {
    params: {
      updateStatus
    }
  })

  return pixData.data
}

async function pixList (bookingId: string, invoiceId: string) {
  if (process.env.REACT_APP_MOCK_PIX) {
    return ({
      id: 1,
      txid: "7978c0c97ea847e78e8849634473c1f1",
      valor: "400.20",
      status: "ATIVA",
      bookingId: "asdasd",
      invoiceId: "4897687"
    })
  }

  const response = await axios.get(`${REACT_APP_API_PIX_ENDPOINT}`, {
    params: {
      bookingId,
      invoiceId
    },
    headers: {
      Authorization: localStorage.getItem("@kinto-solutions/gts/gtsAccessToken")
    }
  })

  return response?.data?.data?.pixList
}

export default {
  generatePix,
  getPixData,
  pixList
}
