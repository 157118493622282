import "dayjs/locale/pt"

import dayjs, { type Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

dayjs.locale("pt")

interface Options {
  UTC?: boolean
  format?: dayjs.OptionType
}

function time (date?: dayjs.ConfigType, options?: Options): Date {
  const dayjsDate = timeAsDayjs(date, options)
  return dayjsDate.toDate()
}

export function timeAsDayjs (date?: dayjs.ConfigType, options?: Options): Dayjs {
  const {
    UTC
  } = options ?? {}

  let timezone = "America/Sao_Paulo"
  if (UTC) timezone = "UTC"

  const dayjsDate = dayjs.tz(date, timezone)

  return dayjsDate
}

export default time
