import { type Reducer } from "redux"

import { type InvoicesState, InvoicesTypes } from "./types"

const INITIAL_STATE: InvoicesState = {
  data: [],
  error: false,
  loading: false,
  selectedInvoice: undefined
}

const reducer: Reducer<InvoicesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InvoicesTypes.GET_INVOICES:
      return { ...state, loading: true }
    case InvoicesTypes.GET_INVOICES_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload }
    case InvoicesTypes.GET_INVOICES_FAILURE:
      return { ...state, loading: false, error: true, data: action.payload }
    case InvoicesTypes.SELECT_INVOICE:
      return { ...state, selectedInvoice: action.payload }
    default:
      return state
  }
}

export default reducer
