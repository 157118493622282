import { all, takeLatest } from "redux-saga/effects"

import { load } from "./booking/sagas"
import { BookingsTypes } from "./booking/types"
import { getInvoices } from "./invoices/sagas"
import { InvoicesTypes } from "./invoices/types"
import { generateLiveness, getLivenessList } from "./liveness/sagas"
import { LivenessTypes } from "./liveness/types"
import { generatePix, getPixData, getPixList } from "./pix/sagas"
import { PixTypes } from "./pix/types"
import { authenticate, refreshAuthentication } from "./signin/sagas"
import { LoginTypes } from "./signin/types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function * rootSaga (): Generator {
  return yield all([
    takeLatest(BookingsTypes.LOAD_REQUEST, load),
    takeLatest(LoginTypes.SIGNIN, authenticate),
    takeLatest(LoginTypes.REFRESH, refreshAuthentication),
    takeLatest(InvoicesTypes.GET_INVOICES, getInvoices),
    takeLatest(PixTypes.GENERATE_PIX, generatePix),
    takeLatest(PixTypes.GET_PIX_DATA, getPixData),
    takeLatest(PixTypes.GET_PIX_LIST, getPixList),
    takeLatest(LivenessTypes.GENERATE_LIVENESS, generateLiveness),
    takeLatest(LivenessTypes.GET_LIVENESS_LIST, getLivenessList)
  ])
}
