import { Tag } from "antd"
import React from "react"

interface OwnProps {
  status: string
}

function statusToColor (status: string): string {
  switch (status) {
    case "ATIVA": return "gold"
    case "CONCLUIDA": return "lime"
    case "REMOVIDA_PELO_PSP": return "magenta"
    case "REMOVIDA_PELO_USUARIO_RECEBEDOR": return "volcano"
    case "EXPIRADO": return "volcano"
    default: return "red"
  }
}

type Props = OwnProps & React.PropsWithChildren

const InvoiceTag = function (props: Props): React.ReactElement {
  const finalColor: string = statusToColor(props.status)
  return (<Tag color={finalColor}>{props.children}</Tag>)
}

export default InvoiceTag
