import { action } from "typesafe-actions"

import { type Pix, PixTypes } from "./types"

export const generatePix = (data: {
  value: number
  info: string
  comments?: Array<{ nome: string, valor: string }>
  bookingId: string
  invoiceId: string
}): { type: PixTypes.GENERATE_PIX } => action(PixTypes.GENERATE_PIX, data)

export const generatePixSuccess = (data: Pix): { type: PixTypes.GENERATE_PIX_SUCCESS, payload: Pix } => action(PixTypes.GENERATE_PIX_SUCCESS, data)

export const generatePixFailure = (data: { errorMessage: string }): { type: PixTypes.GENERATE_PIX_FAILURE } => action(PixTypes.GENERATE_PIX_FAILURE, data)

export const selectPix = (data?: Pix): { type: PixTypes.SELECT_PIX, payload?: Pix } => action(PixTypes.SELECT_PIX, data)

export const getPixData = (data?: string): { type: PixTypes.GET_PIX_DATA, payload?: string } => action(PixTypes.GET_PIX_DATA, data)
export const getPixDataSuccess = (): { type: PixTypes.GET_PIX_DATA_SUCCESS } => action(PixTypes.GET_PIX_DATA_SUCCESS)

export const getPixList = (data: { bookingId: string, invoiceId: string }): { type: PixTypes.GET_PIX_LIST, payload: { bookingId: string, invoiceId: string } } => action(PixTypes.GET_PIX_LIST, data)
export const getPixListSuccess = (data: Pix[]): { type: PixTypes.GET_PIX_LIST_SUCCESS, payload: Pix[] } => action(PixTypes.GET_PIX_LIST_SUCCESS, data)
export const getPixListFailure = (): { type: PixTypes.GET_PIX_LIST_FAILURE } => action(PixTypes.GET_PIX_LIST_FAILURE)

export default {
  generatePix,
  generatePixSuccess,
  generatePixFailure,
  selectPix,
  getPixData,
  getPixList,
  getPixListSuccess,
  getPixListFailure
}
