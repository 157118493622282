import "./InvoicesListItem.css"

import { Col, Row } from "antd"
import React from "react"

import PIXIcon from "../../assets/icons/PIXIcon"
import { type Invoice } from "../../store/ducks/invoices/types"
import InvoiceTag from "../InvoiceTag"

interface OwnProps {
  invoice: Invoice
  onClickPix?: () => void
}

type Props = OwnProps

const InvoicesListItem = function (props: Props): React.ReactElement {
  const { invoice } = props
  return (
    <Row className="invoice-list-item-bg" justify="center" align="middle" style={{ lineHeight: "40px", paddingLeft: "10px" }} onClick={props.onClickPix}>
      <Col xs={18} sm={20} md={18} lg={20} xxl={22}><span>{invoice.id}: R$ {invoice.total}</span></Col>
      <Col xs={6} sm={4} md={3} lg={2} xxl={1} className='disappear-on-small' ><InvoiceTag status={invoice?.status?.key}>{invoice?.status?.label}</InvoiceTag></Col>
      <Col xs={0} md={3} lg={2} xxl={1}>
        {
          invoice?.status?.key !== "paid" && invoice.total > 0
            ? (<div className='invoice-list-item-pix' onClick={props.onClickPix}>
                <PIXIcon style={{ color: "#37B4AA", fontSize: "30px" }}/>
              </div>)
            : <></>
        }

      </Col>
    </Row>
  )
}

export default InvoicesListItem
