/**
 * Action types
 */
export enum BookingsTypes {
  "LOAD_REQUEST" = "@bookings/LOAD_REQUEST",
  "LOAD_SUCCESS" = "@bookings/LOAD_SUCCESS",
  "LOAD_FAILURE" = "@bookings/LOAD_FAILURE",
  "RESET" = "@bookings/RESET"
}

/**
 * Data types
 */
export interface Booking {
  "id"?: string
  "idNum"?: number
  "communityId"?: number
  "status"?: {
    "key"?: string
    "label"?: string
  }
  "type"?: {
    "key"?: string
    "label"?: string
  }
  "createdAt"?: string
  "updatedAt"?: string
  "createdBy"?: string
  "pickUpDatetime"?: string
  "dropOffDatetime"?: string
  "duration"?: string
  "isOneWay"?: boolean
  "durationChargeType"?: {
    "key"?: string
    "label"?: string
  }
  "estimatedCost"?: {
    "amount"?: number
    "currencyName"?: string
    "currencySymbol"?: string
    "distanceThreshold"?: string
    "distanceDescription"?: string
    "description"?: string
    "paymentSchedule"?: Array<{
      "dueDateTime"?: string
      "estimatedCost"?: {
        "amount"?: number
        "currencyName"?: string
        "currencySymbol"?: string
        "distanceThreshold"?: string
        "distanceDescription"?: string
        "description"?: string
      }
    }>
  }
  "invoiceTotal"?: number
  "journey"?: {
    "pickUpDatetime"?: string
    "dropOffDatetime"?: string
    "distance"?: number
    "distanceUnit"?: null
    "duration"?: string
    "fuelLevel"?: null
    "fuelLevelUnit"?: null
  }
  "hasSpecialRequests"?: boolean
  "isDifferentCommunityVehicle"?: boolean
  "_embedded"?: {
    user: {
      email: string
      id: number
      personalInfo: {
        firstName: string
        lastName: string
        mobilePhoneNumber: string
      }
    }
    vehicle: {
      registrationPlate: string
      model: {
        fullName: string
      }
    }
  }
}

/**
 * State type
 */
export interface BookingsState {
  readonly data: Booking
  readonly loading: boolean
  readonly error: boolean
  readonly errorMessage: string
}
