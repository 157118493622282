import "./NotFound.css"

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import BigHeader from "../../components/BigHeader"

const SignIn = (): React.ReactElement => {
  const navigate = useNavigate()
  const [counter, setCounter] = useState<number>(5)

  useEffect(() => {
    if (counter <= 0) navigate("/")

    setTimeout(() => {
      setCounter(counter - 1)
    }, 1000)
  }, [counter])

  return (
    <div className="view">
      <BigHeader />
      <div className="not-found-container">
        404 | Page not found
        <br/>
        Redirection in {counter} seconds
      </div>
    </div>
  )
}

export default SignIn
