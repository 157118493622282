import { combineReducers } from "redux"

import bookingsReducer from "./booking"
import invoicesReducer from "./invoices"
import livenessReducer from "./liveness"
import pixReducer from "./pix"
import authedReducer from "./signin"

export default combineReducers({
  authed: authedReducer,
  bookings: bookingsReducer,
  invoices: invoicesReducer,
  pix: pixReducer,
  liveness: livenessReducer
})
