import { configureStore, type Store } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"

import { type BookingsState } from "./ducks/booking/types"
import { type InvoicesState } from "./ducks/invoices/types"
import { type LivenessState } from "./ducks/liveness/types"
import { type PixState } from "./ducks/pix/types"
import rootReducer from "./ducks/rootReducer"
import rootSaga from "./ducks/rootSaga"
import { type LoginState } from "./ducks/signin/types"

export interface ApplicationState {
  invoices: InvoicesState
  bookings: BookingsState
  liveness: LivenessState
  authed: LoginState
  pix: PixState
}

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store
