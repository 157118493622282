import dayjs from "dayjs"
import { type Reducer } from "redux"

import { type LoginState, LoginTypes } from "./types"

const INITIAL_STATE: LoginState = {
  data: { },
  error: false,
  loading: false,
  errorMessage: "",

  lastAttempt: dayjs(localStorage.getItem("LAST_ATTEMPT")),
  attempts: Number(localStorage.getItem("CURRENT_ATTEMPT") ?? 0),
  blocked: false,

  refreshError: false
}

const reducer: Reducer<LoginState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.SIGNIN:{
      let currentAttempt = state.attempts

      if (dayjs().diff(dayjs(state.lastAttempt), "hours") >= 1) currentAttempt = 1
      else currentAttempt++

      localStorage.setItem("LAST_ATTEMPT", String(dayjs().format("YYYY-MM-DDTHH:mm:ssZ")))
      localStorage.setItem("CURRENT_ATTEMPT", String(currentAttempt))

      return { ...state, loading: true, error: false, refreshError: false, lastAttempt: dayjs(), attempts: currentAttempt }
    }
    case LoginTypes.SIGNIN_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload, attempts: 0 }
    case LoginTypes.SIGNIN_FAILURE:{
      const isBlocked = state.attempts % 5 === 0
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage, data: {}, blocked: isBlocked, blockedUntil: isBlocked ? dayjs().add(((state.attempts / 5)) * 5, "minute") : undefined }
    }
    case LoginTypes.REFRESH:
      return { ...state, refreshError: false }
    case LoginTypes.REFRESH_FAILURE:
      return { ...state, refreshError: true }
    case LoginTypes.REFRESH_SUCCESS: {
      return { ...state, refreshError: false, data: action.payload }
    }
    default:
      return state
  }
}

export default reducer
