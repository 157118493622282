/**
 * Action types
 */
export enum PixTypes {
  "GENERATE_PIX" = "@pix/GENERATE_PIX",
  "GENERATE_PIX_SUCCESS" = "@pix/GENERATE_PIX_SUCCESS",
  "GENERATE_PIX_FAILURE" = "@pix/GENERATE_PIX_FAILURE",
  "SELECT_PIX" = "@pix/SELECT_PIX",
  "GET_PIX_DATA_SUCCESS" = "@pix/GET_PIX_DATA_SUCCESS",
  "GET_PIX_DATA" = "@pix/GET_PIX_DATA",
  "GET_PIX_LIST" = "@pix/GET_PIX_LIST",
  "GET_PIX_LIST_SUCCESS" = "@pix/GET_PIX_LIST_SUCCESS",
  "GET_PIX_LIST_FAILURE" = "@pix/GET_PIX_LIST_FAILURE"
}

/**
 * Data types
 */
export interface Pix {
  id: number
  txid: string
  valor: number
  copiaecola: string
  status: string
  bookingId: string
  invoiceId: string
  generatedAt: string
  expiresAt: string
}

/**
 * State type
 */
export interface PixState {
  readonly data?: Pix[]
  readonly selectedPix?: Pix
  readonly loading: boolean
  readonly error: boolean

  readonly loadingData: boolean

  readonly loadingPixList: boolean
}
