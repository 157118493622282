import { Col, Row } from "antd"
import React from "react"

import { type Invoice } from "../../store/ducks/invoices/types"
import InvoicesListItem from "../InvoicesListItem"
import { texts } from "../../utils/texts"

interface OwnProps {
  invoices: Invoice[]
  onItemClick: (value: Invoice) => void
}

const InvoicesList = function (props: OwnProps): React.ReactElement {
  return (<>

    <Row gutter={[0, 12]} justify="center" >
    <Col span={20} style={{ textAlign: "left" }} >{texts.invoicesList.moreInfo}</Col>
        {props.invoices?.map(value => (
          <Col span={20} key={value.id}><InvoicesListItem invoice={value} onClickPix={() => { props.onItemClick(value) }} /></Col>
        ))}
    </Row>
  </>)
}

export default InvoicesList
