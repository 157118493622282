import "./BookingInvoices.css"

import { CopyOutlined, DownloadOutlined, InfoCircleOutlined, LeftOutlined, LoadingOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Col, Empty, Modal, Row, Tabs, Tooltip } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { QRCode } from "react-qrcode-logo"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators, type Dispatch } from "redux"

import SimboloKinto from "../../assets/SimboloKinto.svg"
import InvoicesList from "../../components/InvoicesList"
import InvoiceTag from "../../components/InvoiceTag"
import PixTag from "../../components/PixTag"
import { type ApplicationState } from "../../store"
import InvoiceActions from "../../store/ducks/invoices/actions"
import { type Invoice } from "../../store/ducks/invoices/types"
import PixActions from "../../store/ducks/pix/actions"
import { type Pix } from "../../store/ducks/pix/types"
import { translatePixStatus } from "../../utils/helpers"
import time from "../../utils/time"

interface StateProps {
  invoices?: Invoice[]
  selectedInvoice?: Invoice
  loading?: boolean
  loadingPixGeneration?: boolean

  selectedPix?: Pix
  loadingPixStatus?: boolean

  pixList?: Pix[]
  loadingPixList?: boolean
}

interface DispatchProps {
  getInvoices: (payload: {
    bookingId: string
  }) => void

  selectInvoice: (data?: Invoice) => void

  generatePix: (data: {
    value: number
    info: string
    comments?: Array<{ nome: string, valor: string }>
    bookingId: string
    invoiceId: string
  }) => void

  getPixData: (data: string) => void

  getPixList: (data: { bookingId: string, invoiceId: string }) => void

  selectPix: (data?: Pix) => void
}

type Props = StateProps & DispatchProps

function mapStateToProps (state: ApplicationState): StateProps {
  return ({
    invoices: state.invoices.data,
    selectedInvoice: state.invoices.selectedInvoice,
    loading: state.invoices.loading,
    loadingPixGeneration: state.pix.loading,
    selectedPix: state.pix.selectedPix,
    loadingPixStatus: state.pix.loadingData,
    pixList: state.pix.data,
    loadingPixList: state.pix.loadingPixList
  })
}

function mapDispatchToProps (dispatch: Dispatch) {
  return bindActionCreators({ ...InvoiceActions, ...PixActions }, dispatch)
}

const BookingInvoices = (props: Partial<Props>): React.ReactElement => {
  const {
    getInvoices,
    getPixList,
    selectInvoice,
    selectPix,
    selectedInvoice,
    generatePix,
    selectedPix,
    getPixData,
    loadingPixStatus,
    loadingPixList
  } = props as Props
  const INITIAL_PIX_TAB = "pix/list"
  const { bookingId } = useParams()
  const [pixModalOpen, setPixModalOpen] = useState(false)
  const [pixTab, setPixTab] = useState(INITIAL_PIX_TAB)
  const [generatePixActive, setGeneratePixActive] = useState(true)
  const [copied, setCopied] = useState("default")

  const qrCodeRef = useRef<QRCode>(null)

  useEffect(() => {
    if (bookingId) getInvoices({ bookingId })
  }, [])

  useEffect(() => {
    setGeneratePixActive(!!selectedInvoice?.total && selectedInvoice?.total > 0 && selectedInvoice?.status?.key === "pending")
  }, [selectedInvoice])

  function selectInvoiceAndOpenModal (invoice: Invoice) {
    getPixList({
      bookingId: bookingId as string,
      invoiceId: String(invoice.id)
    })
    selectInvoice(invoice)
    setPixModalOpen(true)
    setPixTab(INITIAL_PIX_TAB)
  }

  function handleOkModal () {
    setPixModalOpen(false)
    selectInvoice()
    setPixTab(INITIAL_PIX_TAB)
  }

  function handleCancelModal () {
    setPixModalOpen(false)
    selectInvoice()
    setPixTab(INITIAL_PIX_TAB)
  }

  function handlePixGeneration () {
    generatePix({
      bookingId: bookingId as string,
      invoiceId: String(selectedInvoice?.id),
      comments: [],
      info: "Solução PIX",
      value: selectedInvoice?.total ?? 0
    })
    setPixTab("pix/qrcode")
  }

  function handleNewPix () {
    setPixTab("pix/generate")
  }

  function handlePixCopy () {
    navigator.clipboard.writeText(props.selectedPix?.copiaecola ?? "")
      .then(() => { setCopied("success") })
      .catch(error => {
        console.warn(error)
        setCopied("error")
      })
      .finally(() => {
        setTimeout(() => {
          setCopied("default")
        }, 2000)
      })
  }

  function handlePixReload () {
    getPixData(selectedPix?.txid as string)
  }

  function handlePixDownload () {
    const pixElement = document.getElementById("pixqrcode") as HTMLCanvasElement
    const url = pixElement.toDataURL("image/png")
    const link = document.createElement("a")
    link.download = `pix-${bookingId}-${selectedInvoice?.id}.png`
    link.href = url
    link.click()
  }

  function handlePixGoBack () {
    selectPix()
    setPixTab("pix/list")

    getPixList({
      bookingId: bookingId as string,
      invoiceId: String(selectedInvoice?.id)
    })
  }

  function handleReloadPixList () {
    getPixList({
      bookingId: bookingId as string,
      invoiceId: String(selectedInvoice?.id)
    })
  }

  return (
    <div style={{ padding: "10px" }}>
      <Modal
        centered
        open={pixModalOpen}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
        footer={<Button onClick={handleOkModal}>Fechar</Button>}
      >
        <Tabs defaultActiveKey="values">
          <Tabs.TabPane key={"pix"} tab={"PIX"} disabled={!generatePixActive}>
            {
              pixTab === "pix/generate" && (
                <div className="modal-body modal-pix-generate">
                  <Button onClick={handlePixGeneration}>Gerar PIX</Button>
                  <span>Valor: R$ {selectedInvoice?.total}</span>
                </div>
              )
            }
            {
              pixTab === "pix/qrcode" && (
                <div className="modal-body modal-pix-generate">
                  {
                    props.loadingPixGeneration
                      ? (
                        <LoadingOutlined spin style={{ fontSize: "50px" }} />
                        )
                      : (
                        <>
                          <Row justify='start' style={{ width: "100%", cursor: "pointer", position: "absolute", top: 0 }} onClick={handlePixGoBack}><Col><LeftOutlined/></Col></Row>
                          <Row>
                            <Col>
                              <QRCode
                                value={props.selectedPix?.copiaecola}
                                size={300}
                                logoWidth={50}
                                logoHeight={35}
                                ecLevel='M'
                                fgColor={"#00708d"}
                                ref={qrCodeRef}
                                logoImage={SimboloKinto}
                                removeQrCodeBehindLogo
                                id="pixqrcode"
                              />
                            </Col>
                            <Col>
                              <div className="pix-icons">
                                <Tooltip
                                  placement="left"
                                  title="Copiar"

                                  color={
                                    copied === "default"
                                      ? undefined
                                      : copied === "success" ? "lime" : "red"
                                  }
                                >
                                  <CopyOutlined className="clickable" onClick={handlePixCopy} style={{ fontSize: "20px" }} />
                                </Tooltip>
                                <Tooltip title="Download" placement="left">
                                  <DownloadOutlined className="clickable" onClick={handlePixDownload} style={{ fontSize: "20px" }} />
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="pix-info">
                                <span>Valor: R$ {selectedPix?.valor}</span>
                                <span>Gerado em: {time(selectedPix?.generatedAt).asDayjs(false).format("DD/MM/YYYY HH:mm")}</span>
                                <span>Expira em: {time(selectedPix?.expiresAt).asDayjs(false).format("DD/MM/YYYY HH:mm")}</span>
                                <div>
                                  <span>Status:</span>
                                  <PixTag status={selectedPix?.status as string}>{translatePixStatus(selectedPix?.status)}</PixTag>
                                  {
                                    selectedPix?.status === "ATIVA" && (
                                      <Tooltip title="Refresh">
                                        <ReloadOutlined id="reaload-pix-icon" className="clickable" spin={loadingPixStatus} onClick={!loadingPixStatus ? handlePixReload : undefined} />
                                      </Tooltip>
                                    )
                                  }
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                        )
                  }
                </div>
              )
            }
            {
              pixTab === "pix/list" && (
                <div className="modal-body">
                  {
                    loadingPixList
                      ? <LoadingOutlined spin={true} />
                      : (
                        <>
                          {
                            props.pixList?.every(pix => !["ATIVA", "CONCLUIDA"].includes(pix?.status))
                              ? (
                                <Row justify="center">
                                  <Col>
                                    <PlusCircleOutlined style={{ fontSize: "24px", cursor: "pointer", color: "#00708d" }} onClick={handleNewPix}/>
                                  </Col>
                                </Row>
                                )
                              : (
                                <Row justify="end">
                                  <Col>
                                    <ReloadOutlined onClick={handleReloadPixList}/>
                                  </Col>
                                </Row>
                                )
                          }
                          {
                            props.pixList?.map(pix => (
                              <Row
                                key={pix.id}
                                style={{
                                  padding: "10px",
                                  backgroundColor: "#f5f5f5",
                                  marginBottom: "5px",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  selectPix(pix)
                                  setPixTab("pix/qrcode")
                                }}
                              >
                                <Col span={4}>{pix.id}</Col>
                                <Col span={16}>R$ {pix.valor}</Col>
                                <Col span={4}><PixTag status={pix?.status}>{translatePixStatus(pix?.status)}</PixTag></Col>
                              </Row>
                            ))
                          }
                        </>
                        )
                  }
                </div>
              )
            }
          </Tabs.TabPane>
          <Tabs.TabPane key={"values"} tab={"Valores"}>
            <div className="modal-body">
              {
                selectedInvoice?.lineItems.map(value => (<Row key={value.id}>
                  <Col span={19}><span>{value.fee}</span> <Tooltip title={value.description}><InfoCircleOutlined style={{ fontSize: "8px" }} /></Tooltip></Col>
                  <Col span={5}><span>R$ {value.amount.toFixed(2)}</span></Col>
                </Row>
                ))
              }
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key={"info"} tab={"Mais Informações"}>
            <div className="modal-body">
              <Row>
                <Col span={14}>Status</Col>
                <Col span={10}><InvoiceTag status={selectedInvoice?.status?.key as string}>{selectedInvoice?.status?.label}</InvoiceTag></Col>
              </Row>
              <Row>
                <Col span={14}>Propósito</Col>
                <Col span={10}>{selectedInvoice?.purpose?.label}</Col>
              </Row>
              <Row>
                <Col span={14}>Método de cobrança</Col>
                <Col span={10}>{selectedInvoice?.chargeType?.label}</Col>
              </Row>
              <Row>
                <Col span={14}>Taxa</Col>
                <Col span={10}>{selectedInvoice?.taxMode}</Col>
              </Row>
              <Row>
                <Col span={14}>Data de criação</Col>
                <Col span={10}>{time(selectedInvoice?.createdAt, { UTC: true }).asDayjs(false).format("DD/MM/YYYY HH:mm")}</Col>
              </Row>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      {
        props.loading
          ? <LoadingOutlined spin style={{ fontSize: "50px" }} />
          : (
              props.invoices?.length
                ? (
                <InvoicesList invoices={props.invoices} onItemClick={selectInvoiceAndOpenModal} />
                  )
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum Invoice Encontrado" />
            )
      }
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingInvoices)
