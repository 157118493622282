import { action } from "typesafe-actions"

import { type Login, LoginTypes } from "./types"

export const signIn = (data: { username?: string, password?: string }): { type: LoginTypes.SIGNIN } => action(LoginTypes.SIGNIN, data)

export const signInSuccess = (data: Login): { type: LoginTypes.SIGNIN_SUCCESS, payload: { data: Login } } => action(LoginTypes.SIGNIN_SUCCESS, { data })

export const signInFailure = (data: { errorMessage: string }): { type: LoginTypes.SIGNIN_FAILURE } => action(LoginTypes.SIGNIN_FAILURE, data)

export const refresh = (data: { refreshToken: string }): { type: LoginTypes.REFRESH } => action(LoginTypes.REFRESH, data)

export const refreshSuccess = (data: Login): { type: LoginTypes.REFRESH_SUCCESS, payload: { data: Login } } => action(LoginTypes.REFRESH_SUCCESS, { data })

export const refreshFailure = (): { type: LoginTypes.REFRESH_FAILURE } => action(LoginTypes.REFRESH_FAILURE)
