export function translatePixStatus (status?: string): string {
  switch (status) {
    case "ATIVA": return "Ativa"
    case "CONCLUIDA": return "Concluída"
    case "REMOVIDA_PELO_PSP": return "Expirado"
    case "REMOVIDA_PELO_USUARIO_RECEBEDOR": return "Cancelado"
    case "EXPIRADO": return "Expirado"
    default: return ""
  }
}

export function toFormatedPlate (value?: string): string {
  if (value) {
    return value.replace(/[^A-Za-z0-9]/g, "").replace(/(.{3})(.*)/, "$1-$2")
  }

  return ""
}

export function censorEmail (value?: string): string {
  if (value) {
    const [initial, domain] = value.split("@")

    return `${initial[0].padEnd(10, "*")}@${domain}`
  }

  return "Não Informado"
}

export function censorPhone (value?: string): string {
  if (value) {
    return `${value.slice(0, 3)} ${"".padStart(5, "*")}-${value.replace(/ /g, "").slice(-4)}`
  }

  return "Não Informado"
}
